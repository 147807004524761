/* kendo-drawer-container {
    flex: 1 1 auto;
    overflow-y: auto;
}
.k-icon {
    font-size: 20px;
}
.custom-toolbar {
    width: 100%;
    background-color: #f6f6f6;
    line-height: 10px;
    border-bottom: inset;
    border-bottom-width: 1px;
    padding: 3px 8px;
    color: #656565;
}
.mail-box{
    margin-left: 20px;
    font-weight: bold;
    font-size: 17px;
} */

.custom-toolbar {
    background-color: #28533F; /* Matches the header-banner background */
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: start;
}

.custom-toolbar button {
    background: none;
    border: none;
    cursor: pointer;
    color: #FFF; /* Matching the header text color */
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    width: 100%;
}

.custom-toolbar button:hover {
    color: #6FC067 !important; /* Slight color change on hover */
}

kendo-drawer-container {
    display: flex;
    height: 100vh;
    background-color: #28533F; /* Matches the header-banner background */
}

kendo-drawer {
    background-color: #28533F !important; /* Green drawer background */
    /* border-right: 1px solid #E9EDEB; Light border for separation */
    color: #FFF !important;
    width: 100% !important;
    flex-basis: 100% !important;
    border: none !important;
}

kendo-drawer kendo-drawer-item {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    padding: 10px 20px;
    border-bottom: 1px solid #6FC067 !important; /* Slightly lighter green for item borders */
    color: #FFF;
    transition: background 0.3s ease;
}

li.k-drawer-item.k-level-0:hover {
    background-color: #FFF; /* Contrast selected item with white */
}

kendo-drawer kendo-drawer-item.k-state-selected {
    background-color: #FFF; /* Contrast selected item with white */
    color: #28533F !important; /* Dark green text for selected item */
}

li.k-drawer-item.k-level-0.k-selected {
    background-color: #6FC067 !important; /* Lighter green hover effect */
    color: #28533F !important; /* Dark green text for selected item */
}
li.k-drawer-item.k-selected {
    background-color: #6FC067 !important; /* Lighter green hover effect */
    color: #28533F !important; /* Dark green text for selected item */
}

.k-button.k-icon-button.k-button-md.k-rounded-md.k-button-flat-base.k-button-flat {
    width:25px
}

kendo-drawer-content {
    flex: 1;
    background-color: #FFF; /* Matches main background */
    padding: 25px;
    box-sizing: border-box;
}

kendo-drawer-content my-content {
    height: 100%;
}