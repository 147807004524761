app-side-nav {
    flex: 0 0 225px; /* Fixed width for the side nav */
    /* You can adjust the width as needed */
 } 

.report-wrapper {
    display: flex;
    flex-direction: row; 
    width: 100%;
    font-family: 'Roboto', sans-serif;
    background-color: #f6f7f7;
    border-radius: 8px;
}

.main-content {
    padding: 10px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.header h1 {
    font-size: 28px;
    color: #28533F;
}

.header-buttons {
    display: flex;
    gap: 10px;
}

.header-buttons kendo-button {
    background-color: #FF9100;
    color: white;
}

.executive-summary {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.executive-summary h2 {
    font-size: 22px;
    color: #28533F;
    border-bottom: 2px solid #E9EDEB;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.summary-content {
    display: flex;
    justify-content: space-between;
}

.summary-info {
    width: 45%;
}

.summary-overview {
    width: 50%;
}

.summary-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    font-size: 18px;
}

.summary-label {
    font-weight: bold;
    color: #28533F;
    width: 50%;
}

.summary-overview p {
    margin-bottom: 10px;
    color: #424242;
    margin-top: 0px;
}

.summary-overview ul {
    margin: 0;
    padding-left: 20px;
    color: #424242;
}

::ng-deep .summary-overview h2 {
    color: #28533F;
    margin: 0;
}

::ng-deep .summary-info h2 {
    color: #28533F;
    margin: 0;
    border: none;
    border-bottom: none !important;
}

.summary-overview ul li {
    margin-bottom: 5px;
}

.salary-benchmarking {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.salary-benchmarking h2 {
    font-size: 22px;
    color: #28533F;
    border-bottom: 2px solid #E9EDEB;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.salary-benchmarking p {
    margin-bottom: 20px;
    color: #424242;
    font-size: 16px;
}


.salary-table {
    width: 100%;
    overflow-x: auto;
}

.salary-table table {
    width: 100%;
    border-collapse: collapse;
}

.salary-table th, .salary-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #E9EDEB;
    font-size: 14px;
}

.salary-table th {
    background-color: #f6f7f7;
    color: #28533F;
    font-weight: bold;
}

.salary-table tr:nth-child(even) {
    background-color: #f6f7f7;
}

.salary-table td {
    color: #424242;
}

.salary-table td.competitive {
    background-color: #dff0d8;
    color: #3c763d;
    font-weight: bold;
}

.salary-table td.slightly-low {
    background-color: #fcf8e3;
    color: #8a6d3b;
    font-weight: bold;
}

.salary-table td.below-average {
    background-color: #f2dede;
    color: #a94442;
    font-weight: bold;
}

.strategy {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.strategy h2 {
    font-size: 22px;
    color: #28533F;
    border-bottom: 2px solid #E9EDEB;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.strategy h3 {
    font-size: 20px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    text-decoration: underline;
}

.strategy-item h3 {
    font-size: 18px;
    color: #28533F;
    margin-bottom: 10px;
    text-decoration: none;
}

.strategy-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.conclusion {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.conclusion h2 {
    font-size: 22px;
    color: #28533F;
    border-bottom: 2px solid #E9EDEB;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.conclusion-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.kendo-chatbox-wrapper {
    background-color: #E9EDEB; /* Light grey background */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin: 20px;
    max-width: 300px; /* Adjust as needed */
    position: fixed;
    right: 20px;
    top: 100px;
}

.report-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    font-family: 'Roboto', sans-serif;
    background-color: #f6f7f7;
    border-radius: 8px;
  }
  
  .main-content-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between; /* Ensure chatbox stays to the right */
  }
  
  .main-content {
    flex-grow: 1;
    padding: 10px;
    width: 90%;
  }
  
  .chatbox-container {
    background-color:  #ff9100;
    width: 300px; /* Fixed width for the chatbox */
    /* padding: 10px; */
    margin-left: 20px;
    position: fixed;
    right: 20px; /* Position it 20px from the right */
    bottom: 20px; /* Position it 20px from the bottom */
    z-index: 1000; /* Ensure it's on top of other elements */
    border: 2px solid #28533F; /* Add a border to make it stand out */
    border-radius: 8px; /* Optional: Add border radius for rounded corners */
  }
  
  .kendo-chatbox-wrapper {
    background-color: #e9edeb; /* Light grey background */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 100%; 
    position: relative;
    /* position: sticky; 
    top: 100px;  */
  }
  
  .k-chat {
    background-color: white;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;
    border: none;
  }
  
  .k-message {
    background-color: white;
    border-radius: 20px;
    padding: 12px 20px;
    margin-bottom: 12px;
    max-width: 100%;
    font-size: 14px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  }
  
  .k-message.k-message-user .k-message-content {
    background-color: #4caf50;
    color: white;
  }
  
  .k-message.k-message-bot .k-message-content {
    background-color: #e0e0e0;
    color: #333;
  }
  
  .k-chat-input {
    border-radius: 20px;
    padding: 12px 20px;
    font-size: 14px;
    background-color: white;
    border: 1px solid #e0e0e0;
  }
  
  .k-button {
    background-color: #ff9100;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 8px 16px;
    font-size: 14px;
  }

  .collapsed-chatbox {
    background-color: #28533F;
    color: white;
    padding: 10px;
    border-radius: 8px;
    border: 2px solid #28533F;
    width: 300px;
    text-align: center;
    cursor: pointer;
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 1000;
  }
  
  .collapsed-chatbox p {
    margin: 0;
  }


  .kendo-chatbox-wrapper {
    background-color: #E9EDEB; /* Ensuring consistent light grey background */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    position: relative;
    /* Adjust as needed */
}

.k-chat {
    background-color: white; /* Consistent background for chat area */
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;
    border: none;
}

.k-message {
    background-color: white; /* Match background with site theme */
    border-radius: 20px;
    padding: 12px 20px;
    margin-bottom: 12px;
    max-width: 100%;
    font-size: 14px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.k-message.k-message-user .k-message-content {
    background-color: #4caf50;
    color: white;
}

.k-message.k-message-bot .k-message-content {
    background-color: #e0e0e0;
    color: #333;
}

.k-chat-input {
    background-color: white; /* Ensure input area matches theme */
    border-radius: 20px;
    padding: 12px 20px;
    font-size: 14px;
    border: 1px solid #e0e0e0; /* Subtle border to match theme */
}

.k-button {
    background-color: #ff9100;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 8px 16px;
    font-size: 14px;
}

/* Ensuring the chatbox remains consistent in its layout */
.chatbox-container {
    width: 300px; /* Fixed width for the chatbox */
    margin-left: 20px;
    position: fixed;
    right: 20px; /* Position it 20px from the right */
    bottom: 20px; /* Position it 20px from the bottom */
    z-index: 1000; /* Ensure it's on top of other elements */
    border: 2px solid #28533F; /* Add a border to make it stand out */
    border-radius: 8px; /* Add border radius for rounded corners */
}

.collapsed-chatbox {
    background-color: #28533F;
    color: white;
    padding: 10px;
    border-radius: 8px;
    border: 2px solid #28533F;
    width: 300px;
    text-align: center;
    cursor: pointer;
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 1000;
}

.collapsed-chatbox p {
    margin: 0;
}

.filter-bar {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.filter-bar kendo-dropdownbutton {
    background-color: #66bb6a; /* Match the green background */
    color: white; /* White text color */
    border-radius: 20px;
    padding: 0px 10px;
    font-size: 14px;
    font-weight: bold;
    border: none;
    box-shadow: none;
  }

  ::ng-deep .filter-bar kendo-dropdownbutton > button[kendobutton] {
    background-color: #66bb6a; /* Match the green background */
    color: white; /* White text color */
    border: none;
    box-shadow: none;
  }

  .filter-bar kendo-dropdownbutton:hover {
    background-color: #57a156; /* Slightly darker green on hover */
  }
  
  .filter-bar kendo-dropdownbutton.active {
    border: 2px solid #66bb6a; /* Green border when active */
    background-color: white;
    color: #66bb6a;
  }
  
  .filter-bar kendo-dropdownbutton .k-icon {
    display: none; /* Hide the default icon */
  }
  
  .filter-bar kendo-dropdownbutton::after {
    content: '\25BC'; /* Unicode for down arrow */
    margin-left: 10px;
    margin-top: 10px;
    color: inherit;
  }

  .peer-organizations h3 {
    font-size: 20px; /* Slightly larger than paragraph text */
    color: #28533F; /* Match heading color */
    margin-bottom: 15px; /* Consistent spacing below headings */
}

  .peer-characteristics {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Creates 2 equal-width columns */
    gap: 20px; /* Adds space between the items */
    font-family: 'Roboto', sans-serif; /* Consistent font */
}

.characteristic {
    background-color: white; /* Match the background color */
    padding: 20px; /* Consistent padding */
    border-radius: 8px; /* Consistent border radius */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Match box-shadow */
    color: #424242; /* Consistent text color */
}

.characteristic-title {
    font-size: 18px; /* Consistent font size */
    color: #28533F; /* Consistent color */
    margin-bottom: 10px; /* Spacing below title */
}

.characteristic-description {
    font-size: 16px; /* Consistent font size for descriptions */
    color: #424242; /* Consistent text color */
    margin-bottom: 15px; /* Space between paragraphs */
    line-height: 1.5; /* Improved readability */
}

.description {
    color: #424242; /* Match text color */
    font-size: 16px; /* Ensure font size consistency */
    line-height: 1.6; /* Adjust line-height for readability */
    margin-bottom: 20px; /* Consistent spacing */
}

.description p {
    margin-bottom: 20px; /* Consistent paragraph spacing */
    color: #424242; /* Match text color */
}

.description h3 {
    font-size: 20px; /* Slightly larger than paragraph text */
    color: #28533F; /* Match heading color */
    margin-bottom: 15px; /* Consistent spacing below headings */
}

.description ul {
    margin: 0;
    padding-left: 20px; /* Add space for bullets */
    color: #424242; /* Match text color */
}

.description ul li {
    margin-bottom: 10px; /* Spacing between list items */
    font-size: 16px; /* Match font size with the rest of the content */
    line-height: 1.6; /* Consistent line height */
}

.description ul li strong {
    color: #28533F; /* Emphasize the important text */
}

.k-pdf-export .noExport {
    display: none;
}