.app-wrap {
   /* height: 100%; */
   /* width: 100%; */
   display: flex;
   flex-direction: column;
   height: 100vh;
}

.app-header {
   height: 84px; /* Adjust based on the actual height of your header */
   width: 100%;
   z-index: 1000;
   /* margin-bottom: 25px; */
}


.app-body {
   display: flex;
   flex-direction: column;
   height: 100vh;
   /* width: 100%; */
   /* Additional styling */
}
.layout {
   display: flex;
   flex: 1;
}

.sidebar {
   flex: 0 0 250px; /* Fixed width for the sidebar */
}

.main-content {
   flex: 1;
   overflow: auto;
}

.home-auth-button-container{
   align-items: center;
   justify-content: center;
}

.home-auth-button-container button{
   padding: 15px 30px;
   font-size: 18px;
   margin: 10px;
}

.content-wrapper {
   display: flex;
   flex-direction: row; /* Arrange children in a row */
}

app-side-nav {
   flex: 0 0 225px; /* Fixed width for the side nav */
   /* You can adjust the width as needed */
}

.main-content {
   flex: 1; /* Take up the remaining space */
}